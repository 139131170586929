<template>
  <div class="appraisal-list">
    <h2 class="appraisal-list-not-desktop-title"><span>搬家估價單</span></h2>
    <h2 class="appraisal-list-title">五路搬家估價單</h2>
    <form action="" class="appraisal-list-form">
      <div class="appraisal-list-form-group1">
        <label class="appraisal-list-form-label" for="appraisal-list-form-name">姓名<span class="appraisal-list-form-need">*</span><input class="appraisal-list-form-input" id="appraisal-list-form-name" type="text" v-model="userName"></label>
        <div class="appraisal-list-form-group1-not-desktop-error-message" v-if="userNameErrorMessage">
          <span v-if="userNameErrorMessage">{{ userNameErrorMessage }}</span>
        </div>
        <label class="appraisal-list-form-label" for="appraisal-list-form-phone">電話<span class="appraisal-list-form-need">*</span><input class="appraisal-list-form-input" id="appraisal-list-form-phone" type="tel" v-model="userPhone"></label>
        <div class="appraisal-list-form-group1-not-desktop-error-message" v-if="userPhoneErrorMessage">
          <span v-if="userPhoneErrorMessage">{{ userPhoneErrorMessage }}</span>
        </div>
      </div>
      <div class="appraisal-list-form-group1-error-message" v-if="userNameErrorMessage || userPhoneErrorMessage">
        <span v-if="userNameErrorMessage">{{ userNameErrorMessage }}</span>
        <span class="appraisal-list-form-group1-error-message-phone" v-if="userPhoneErrorMessage">{{ userPhoneErrorMessage }}</span>
      </div>
      <div class="appraisal-list-form-group2">
        <label class="appraisal-list-form-label" for="">LINE 名稱<span class="appraisal-list-form-need">*</span><input type="text" class="appraisal-list-form-input" v-model="userLineName"></label>
      </div>
      <div class="appraisal-list-form-group2-error-message" v-if="userLineNameErrorMessage">
        <span>{{ userLineNameErrorMessage }}</span>
      </div>
      <div class="appraisal-list-form-group2">
        <label class="appraisal-list-form-label" for="">預計搬遷日期<span class="appraisal-list-form-need">*</span><input type="date" class="appraisal-list-form-input" :value="expectMoveDate" @input="updateExpectMoveDate"></label>
      </div>
      <div class="appraisal-list-form-group2-error-message" v-if="expectMoveDateErrorMessage">
        <span>{{ expectMoveDateErrorMessage }}</span>
      </div>
      <div class="appraisal-list-form-group2">
        <label class="appraisal-list-form-label" for="">遷出地址<span class="appraisal-list-form-need">*</span><input type="text" class="appraisal-list-form-input" v-model="moveOutAddress"></label>
      </div>
      <div class="appraisal-list-form-group2-error-message" v-if="moveOutAddressErrorMessage">
        <span>{{ moveOutAddressErrorMessage }}</span>
      </div>
      <div class="appraisal-list-form-group2">
        <label class="appraisal-list-form-label" for="">遷入地址<span class="appraisal-list-form-need">*</span><input type="text" class="appraisal-list-form-input" v-model="moveInAddress"></label>
      </div>
      <div class="appraisal-list-form-group2-error-message" v-if="moveInAddressErrorMessage">
        <span>{{ moveInAddressErrorMessage }}</span>
      </div>
      <!-- <p class="appraisal-list-form-question">是否自行前往? <span class="appraisal-list-form-need">*</span></p> -->
      <p class="appraisal-list-form-remarks">送出前，請加入五路搬家 LINE 官方 ID：@787eypuh (或是按右下角綠色連結直接加入)<span class="appraisal-list-form-need">*</span></p>
      <label class="appraisal-list-form-option" for="go-by-self">
        <div class="appraisal-list-form-option-input">
          <input type="radio" id="go-by-self" :value="true" v-model="isJoinLineOfficialAccount">
          <span class="appraisal-list-form-option-input-control">已加入五路 LINE 官方帳號</span>
        </div>
      </label>
      <div class="appraisal-list-form-group2-error-message" v-if="joinLineOfficialAccountErrorMessage">
        <span>{{ joinLineOfficialAccountErrorMessage }}</span>
      </div>
      <div class="appraisal-list-form-submit" v-if="!isSentSuccess">
        <a @click.prevent="sendAllUserMoveInfo" class="appraisal-list-form-submit-link" href="#">送出</a>
      </div>
      <p class="appraisal-list-form-submit-success" v-if="isSentSuccess">估價單已成功送出</p>
    </form>
  </div>
</template>

<script>
// import { ref, onMounted } from 'vue'
import { ref } from 'vue'
import axios from 'axios'
import { useMeta } from 'vue-meta'

export default {
  name: 'AppraisalList',
  setup () {
    const userName = ref('')
    const userPhone = ref('')
    const userLineName = ref('')
    const expectMoveDate = ref('')
    const moveOutAddress = ref('')
    const moveInAddress = ref('')
    const isUserNameValid = ref(false)
    const isUserPhoneValid = ref(false)
    const isUserLineNameValid = ref(false)
    const isMoveOutAddressValid = ref(false)
    const isMoveInAddressValid = ref(false)
    const isJoinLineOfficialAccount = ref(false)
    const isSentSuccess = ref(false)
    const userNameErrorMessage = ref('')
    const userPhoneErrorMessage = ref('')
    const userLineNameErrorMessage = ref('')
    const expectMoveDateErrorMessage = ref('')
    const moveOutAddressErrorMessage = ref('')
    const moveInAddressErrorMessage = ref('')
    const joinLineOfficialAccountErrorMessage = ref('')

    const updateExpectMoveDate = (e) => {
      expectMoveDate.value = e.target.value
    }

    const sendAllUserMoveInfo = async () => {
      const url = '/v2/bot/message/push'
      // LINE official account
      // 五路搬家 LINE 官方帳號
      const channelAccessToken = 'ECWZeZWwreEBm6kFSrMNDUI35hq69lFNCv4KG9Bllm29+9xnm9IUydNH2wmI8zdT+8pkzxT5S0OKR4hpvQbD50xIu67F55sKFA2+OFXq2Do0qy6w9ur9B4bcWFYEO4+ScbpthXJqmMozR/TNPGioWgdB04t89/1O/w1cDnyilFU='
      // const userID = 'U587892457dfd5862b26f90b6893bebb9'
      const groupId = 'Cc2bf9c1766915dc567ef87e78d932df7'
      // 測試用 LINE 官方帳號
      // const channelAccessToken = 'ewa1fYYhBSgCrAUcVdQlapeg80KZHVm//XAtE7aiizeXS0Y4XhXJsZJjpNBtGJKdi0/Dc0+bkBYJYLMrEn1P6UgvipNf1NCZZIg/1+iIqlaTtgWv/btARTrsmhDuuFSONensMatmGxJgkY/JJNog2AdB04t89/1O/w1cDnyilFU='
      // const userID = 'Ub870a60895e102275d5ab0ce5dfd4e99'
      // const groupId = 'Cda7fb47c27eabcc1f4fa331978d7345a'

      const data = JSON.stringify({
        to: groupId,
        messages: [
          {
            type: 'text',
            text: `姓名：${userName.value.trim()}
電話：${userPhone.value.trim()}
LINE 名稱：${userLineName.value.trim()}
預計搬遷日期：${expectMoveDate.value.trim()}
遷出地址：${moveOutAddress.value.trim()}
遷入地址：${moveInAddress.value.trim()}`
          }
        ]
      })

      validateUserName()
      validateUserPhone()
      validateUserLineName()
      validateExpectMoveDate()
      validateMoveOutAddress()
      validateMoveInAddress()
      validateJoinLineOfficial()

      if (!isUserNameValid.value || !isUserPhoneValid.value || !isUserLineNameValid.value || expectMoveDate.value === '' || !isMoveOutAddressValid.value || !isMoveInAddressValid.value || !isJoinLineOfficialAccount.value) {
        return
      }
      try {
        await axios.post(url, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + channelAccessToken
          }
        }).then(resolve => {
          if (resolve.status === 200) {
            isSentSuccess.value = !isSentSuccess.value
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
    const validateUserName = () => {
      const userNameRegexp = /^[\u4e00-\u9fa5]{2,10}$|^[A-Za-z]{3,20}$/
      isUserNameValid.value = userNameRegexp.test(userName.value)
      if (!isUserNameValid.value) {
        if (userName.value.trim().length > 0) {
          userNameErrorMessage.value = '請勿輸入空白字元與特殊符號'
        } else if (userName.value.trim().length === 0) {
          userNameErrorMessage.value = '請輸入姓名'
        }
      } else {
        userNameErrorMessage.value = ''
      }
    }
    const validateUserPhone = () => {
      const userPhoneRegexp = /^[0-9]{8,10}$|^[0-9]{2,4}-[0-9]{5,8}$|^[0-9]{2,4}-[0-9]{1,4}-[0-9]{4}$|^[0-9]{4}-[0-9]{3}-[0-9]{3}$|^[0-9]{4}-[0-9]{6}$/
      isUserPhoneValid.value = userPhoneRegexp.test(userPhone.value)
      if (!isUserPhoneValid.value) {
        if (userPhone.value.length > 0) {
          userPhoneErrorMessage.value = '請輸入正確的電話或手機號碼'
        } else if (userPhone.value.length === 0) {
          userPhoneErrorMessage.value = '請輸入電話或手機號碼'
        }
      } else {
        userPhoneErrorMessage.value = ''
      }
    }
    const validateUserLineName = () => {
      if (userLineName.value.trim().length < 1) {
        userLineNameErrorMessage.value = '請輸入 LINE 名稱'
      } else {
        isUserLineNameValid.value = true
        userLineNameErrorMessage.value = ''
      }
    }
    const validateExpectMoveDate = () => {
      if (expectMoveDate.value.length === 0) {
        expectMoveDateErrorMessage.value = '請選擇預計搬遷日期'
      } else {
        expectMoveDateErrorMessage.value = ''
      }
    }
    const validateMoveOutAddress = () => {
      const moveOutAddressRegexp = /^[A-Za-z0-9-\s\u4E00-\u9FA5]{1,40}$/
      isMoveOutAddressValid.value = moveOutAddressRegexp.test(moveOutAddress.value)
      // console.log('isMoveOutAddressValid.value=>', isMoveOutAddressValid.value)
      if (moveOutAddress.value.length === 0) {
        moveOutAddressErrorMessage.value = '請輸入遷出地址'
      } else {
        moveOutAddressErrorMessage.value = ''
      }
      if (moveOutAddress.value.length > 0 && !isMoveOutAddressValid.value) {
        moveOutAddressErrorMessage.value = '請輸入正確的遷出地址'
      } else if (moveOutAddress.value.length > 0 && isMoveOutAddressValid.value) {
        moveOutAddressErrorMessage.value = ''
      }
    }
    const validateMoveInAddress = () => {
      const moveInAddressRegexp = /^[A-Za-z0-9-\s\u4E00-\u9FA5]{1,40}$/
      isMoveInAddressValid.value = moveInAddressRegexp.test(moveInAddress.value)
      if (moveInAddress.value.length === 0) {
        moveInAddressErrorMessage.value = '請輸入遷入地址'
      } else {
        moveInAddressErrorMessage.value = ''
      }
      if (moveInAddress.value.length > 0 && !isMoveInAddressValid.value) {
        moveInAddressErrorMessage.value = '請輸入正確的遷入地址'
      } else if (moveInAddress.value.length > 0 && isMoveInAddressValid.value) {
        moveInAddressErrorMessage.value = ''
      }
    }
    const validateJoinLineOfficial = () => {
      if (isJoinLineOfficialAccount.value) {
        joinLineOfficialAccountErrorMessage.value = ''
      } else {
        joinLineOfficialAccountErrorMessage.value = '請先加入五路 LINE 官方帳號'
      }
    }
    // onMounted(() => {

    // })

    useMeta({
      title: '搬家估價單'
    })

    return { userName, userPhone, userLineName, expectMoveDate, moveOutAddress, moveInAddress, userNameErrorMessage, userPhoneErrorMessage, expectMoveDateErrorMessage, moveOutAddressErrorMessage, moveInAddressErrorMessage, userLineNameErrorMessage, joinLineOfficialAccountErrorMessage, updateExpectMoveDate, sendAllUserMoveInfo, validateUserName, validateUserPhone, validateUserLineName, validateExpectMoveDate, validateMoveOutAddress, validateMoveInAddress, isUserNameValid, isUserPhoneValid, isUserLineNameValid, isMoveOutAddressValid, isMoveInAddressValid, isJoinLineOfficialAccount, isSentSuccess }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.appraisal-list {
  padding-left: calc(128 / 4000 * 100vw);
  @include iPad() {
    padding-left: 0;
    min-height: 90vh;
  }
  &-not-desktop {
    &-title {
      display: none;
      @include iPad() {
        display: flex;
        justify-content: center;
        margin-top: calc(77 / 1563 * 100vw);
        margin-bottom: calc(208 / 1563 * 100vw);
      }
      span {
        padding: calc(40 / 1563 * 100vw) calc(120 / 1563 * 100vw);
        color: #ffffff;
        background-color: #2e3f8d;
        border-radius: 50rem;
        font-size: calc(60 / 1563 * 100vw);
      }
    }
  }
  &-title {
    font-size: calc(84 / 4000 * 100vw);
    letter-spacing: .2rem;
    margin-bottom: calc(88 / 2250 * 100vh);
    @include iPad() {
      font-size: calc(96 / 1563 * 100vw);
      padding-left: calc(220 / 1563 * 100vw);
    }
  }
  &-form {
    @include iPad() {
      display: flex;
      flex-direction: column;
      // width: 72vw;
      padding-right: calc(200 / 1563 * 100vw);
      padding-left: calc(208 / 1563 * 100vw);
    }
    &-group1 {
      display: flex;
      margin-bottom: calc(60 / 4000 * 100vw);
      @include iPad() {
        flex-direction: column;
        // align-self: start;
        // align-items: flex-start;
        width: 55vw;
        margin-bottom: 0;
        // margin-left: calc(180 / 1563 * 100vw);
      }
      label {
        @include iPad() {
          // padding-right: calc(60 / 1563 * 100vw);
          // padding-left: calc(60 / 1563 * 100vw);
          margin-bottom: calc(70 / 1563 * 100vw);
        }
        &:nth-child(1) {
          margin-right: calc(62 / 4000 * 100vw);
          @include iPad() {
            margin-right: 0;
          }
        }
        input {
          width: calc(730 / 4000 * 100vw);
        }
      }
      &-not-desktop {
        &-error-message {
          display: none;
          @include iPad() {
            display: block;
            color: #ff0000;
            margin-bottom: calc(52 / 1563 * 100vw);
            font-size: calc(46 / 1563 * 100vw);
          }
        }
      }
      &-error-message {
        color: #ff0000;
        margin-bottom: calc(60 / 4000 * 100vw);
        &-phone {
          margin-left: calc(1200 / 4000 * 100vw);
        }
        @include iPad() {
          display: none;
        }
      }
    }
    &-group2 {
      background-color: #e8e8e8;
      width: calc(1280 / 4000 * 100vw);
      margin-bottom: calc(60 / 4000 * 100vw);
      @include iPad() {
        width: calc(1146 / 1563 * 100vw);
        margin-bottom: calc(52 / 1563 * 100vw);
      }
      &-error-message {
        color : #ff0000;
        margin-bottom: calc(60 / 4000 * 100vw);
        @include iPad() {
          font-size: calc(46 / 1563 * 100vw);
          margin-bottom: calc(52 / 1563 * 100vw);
        }
      }
    }
    &-label {
      display: flex;
      font-size: calc(56 / 4000 * 100vw);
      letter-spacing: .2rem;
      padding: calc(37 / 2250 * 100vh) calc(56 / 4000 * 100vw);
      background-color: #e8e8e8;
      @include iPad() {
        font-size: calc(46 / 1563 * 100vw);
        padding: calc(30 / 1563 * 100vh) calc(60 / 1563 * 100vw);
      }
    }
    &-need {
      color: #e60014;
      font-size: calc(95 / 4000 * 100vw);
      vertical-align: top;
      @include iPad() {
        font-size: calc(100 / 1563 * 100vw);
      }
    }
    &-input {
      flex: 1;
      border: none;
      border-bottom: 1px solid #727272;
      font-size: calc(48 / 4000 * 100vw);
      background-color: #e8e8e8;
      padding-right: calc(40 / 4000 * 100vw);
      padding-left: calc(40 / 4000 * 100vw);
      // margin-left: calc(16 / 4000 * 100vw);
      @include iPad() {
        font-size: calc(48 / 1563 * 100vw);
      }
      &:focus {
        outline: none;
      }
    }
    &-submit {
      display: flex;
      @include iPad() {
        // width: calc(508 / 1563 * 100vw);
        // justify-content: center;
        font-size: calc(80 / 1563 * 100vw);
        // padding-top: calc(40 / 1563 * 100vw);
        // padding-right: calc(160 / 1563 * 100vw);
        // padding-bottom: calc(40 / 1563 * 100vw);
        // padding-left: calc(160 / 1563 * 100vw);
        margin-top: calc(60 / 1563 * 100vw);
        margin-bottom: calc(160 / 1563 * 100vw);
        margin-left: calc(348 / 1563 * 100vw);
      }
      &-link {
        display: inline-block;
        font-size: calc(57 / 4000 * 100vw);
        font-family: Taipei Sans TC;
        font-style: normal;
        font-weight: 400;
        color: #ffffff;
        text-decoration: none;
        // text-align: center;
        direction:rtl;
        letter-spacing: .75rem;
        text-indent: -.75rem;
        background-color: #4b65a2;
        border: none;
        border-radius: 50rem;
        padding-top: calc(28 / 4000 * 100vw);
        padding-right: calc(56 / 4000 * 100vw);
        padding-bottom: calc(28 / 4000 * 100vw);
        padding-left: calc(56 / 4000 * 100vw);
        margin-top: calc(40 / 4000 * 100vw);
        margin-bottom: calc(160 / 4000 * 100vw);
        margin-left: calc(800 / 4000 * 100vw);
        @include iPad() {
          font-size: calc(63 / 1563 * 100vw);
          margin-top: calc(16 / 1563 * 100vw);
          margin-left: 0;
          padding: calc(47 / 1563 * 100vw) calc(140 / 1563 * 100vw);
        }
      }
      &-success {
        margin-left: calc(800 / 4000 * 100vw);
        color: #2c9e2c;
        @include iPad() {
          margin-left: 0;
          text-align: center;
          padding-right: calc(208 / 1563 * 100vw);
          margin-top: calc(60 / 1563 * 100vw);
          margin-bottom: calc(60 / 1563 * 100vw);
        }
      }
    }
    // &-question {
    //   font-size: calc(56 / 4000 * 100vw);
    //   margin-bottom: calc(60 / 4000 * 100vw);
    // }
    &-option {
      display: flex;
      align-items: center;
      font-size: calc(48 / 4000 * 100vw);
      margin-bottom: calc(40 / 4000 * 100vw);
      @include iPad() {
        font-size: calc(40 / 1563 * 100vw);
        margin-bottom: calc(40 / 1563 * 100vw);
      }
      &-input {
        display: flex;
        align-items: center;
        @include iPad() {
        }
        input[type='radio'] {
          margin-top: 0;
          width: calc(40 / 4000 * 100vw);
          height: calc(40 / 4000 * 100vw);
          @include iPad() {
            width: calc(56 / 1563 * 100vw);
            height: calc(56 / 1563 * 100vw);
          }
        }
        &-control {
          margin-left: calc(20 / 4000 * 100vw);
          @include iPad() {
            font-size: calc(46 / 1563 * 100vw);
          }
        }
      }
    }
    &-remarks {
      font-size: calc(52 / 4000 * 100vw);
      margin-top: calc(80 / 4000 * 100vw);
      line-height: 1.5;
      @include iPad() {
        font-size: calc(46 / 1563 * 100vw);
      }
      // margin-bottom: calc(10 / 4000 * 100vw);
    }
  }
}

// reference: https://stackoverflow.com/questions/14507119/is-it-possible-to-style-the-default-placeholder-text-on-an-html5-input-type-dat
::-webkit-datetime-edit-month-field {
  margin-left: 12px;
  margin-right: 12px;
  @include iPad() {
    margin: 0 6px;
  }
}

::-webkit-datetime-edit-day-field {
  margin-left: 12px;
  @include iPad() {
    margin-left: 6px;
  }
}

::-webkit-datetime-edit-year-field {
  margin-left: 12px;
    @include iPad() {
    margin-right: 0px;
    margin-left: 0px;
  }
}

</style>
