<template>
  <div class="move-house-tidbits">
    <h2 class="move-house-tidbits-title"><span>搬家花絮</span></h2>
    <ul class="move-house-tidbits-list">
      <li class="move-house-tidbits-list-item">
        <router-link class="move-house-tidbits-list-item-link" :to="{ name: 'MoveHouseTidbitsAlbum', params: { id: 1 } }">
          <img class="move-house-tidbits-list-item-link-photo" src="../assets/pagination-move-house-tidbits-1.jpg" alt="">
          <p class="move-house-tidbits-list-item-link-type">搬家過程</p>
        </router-link>
      </li>
      <li class="move-house-tidbits-list-item">
        <router-link class="move-house-tidbits-list-item-link" :to="{ name: 'MoveHouseTidbitsAlbum', params: { id: 2 } }">
          <img class="move-house-tidbits-list-item-link-photo" src="../assets/pagination-move-house-tidbits-2.jpg" alt="">
          <p class="move-house-tidbits-list-item-link-type">檔案銷毀</p>
        </router-link>
      </li>
      <li class="move-house-tidbits-list-item">
        <router-link class="move-house-tidbits-list-item-link" :to="{ name: 'MoveHouseTidbitsAlbum', params: { id: 3 } }">
          <img class="move-house-tidbits-list-item-link-photo" src="../assets/pagination-move-house-tidbits-3.jpg" alt="">
          <p class="move-house-tidbits-list-item-link-type">莊園整理</p>
        </router-link>
      </li>
      <li class="move-house-tidbits-list-item">
        <router-link class="move-house-tidbits-list-item-link" :to="{ name: 'MoveHouseTidbitsAlbum', params: { id: 4 } }">
          <img class="move-house-tidbits-list-item-link-photo" src="../assets/pagination-move-house-tidbits-4.jpg" alt="">
          <p class="move-house-tidbits-list-item-link-type">莊園整理-割草</p>
        </router-link>
      </li>
      <li class="move-house-tidbits-list-item">
        <router-link class="move-house-tidbits-list-item-link" :to="{ name: 'MoveHouseTidbitsAlbum', params: { id: 5 } }">
          <img class="move-house-tidbits-list-item-link-photo" src="../assets/pagination-move-house-tidbits-5.jpg" alt="">
          <p class="move-house-tidbits-list-item-link-type">裝潢拆除</p>
        </router-link>
      </li>
      <li class="move-house-tidbits-list-item">
        <router-link class="move-house-tidbits-list-item-link" :to="{ name: 'MoveHouseTidbitsAlbum', params: { id: 6 } }">
          <img class="move-house-tidbits-list-item-link-photo" src="../assets/pagination-move-house-tidbits-6.jpg" alt="">
          <p class="move-house-tidbits-list-item-link-type">傢俱回收</p>
        </router-link>
      </li>
      <li class="move-house-tidbits-list-item">
        <router-link class="move-house-tidbits-list-item-link" :to="{ name: 'MoveHouseTidbitsAlbum', params: { id: 7 } }">
          <img class="move-house-tidbits-list-item-link-photo" src="../assets/pagination-move-house-tidbits-7.jpg" alt="">
          <p class="move-house-tidbits-list-item-link-type">回頭車</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'MoveHouseTidbits',
  setup () {
    useMeta({
      title: '搬家花絮照片'
    })
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.move-house-tidbits {
  padding-left: calc(95 / 4000 * 100vw);
  @include iPad() {
    padding-left: 0;
  }
  &-title {
    display: none;
    @include iPad() {
      display: flex;
      justify-content: center;
      margin-top: calc(40 / 1563 * 100vw);
      margin-bottom: calc(120 / 1563 * 100vw);
    }
    span {
      padding: calc(40 / 1563 * 100vw) calc(80 / 1563 * 100vw);
      color: #ffffff;
      background-color: #2e3f8d;
      border-radius: 50rem;
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    @include iPad() {
      justify-content: center;
    }
    &-item {
      width: calc(486 / 2673 * 100vw);
      padding-top: calc(39 / 4000 * 100vw);
      padding-right: calc(35 / 4000 * 100vw);
      padding-bottom: calc(39 / 4000 * 100vw);
      padding-left: calc(35 / 4000 * 100vw);
      margin-right: calc(31 / 4000 * 100vw);
      margin-bottom: calc(60 / 4000 * 100vw);
      margin-left: calc(31 / 4000 * 100vw);
      background-color: #f0f0f0;
      @include iPad() {
        width: calc(580 / 1563 * 100vw);
        padding: calc(32 / 1563 * 100vw) calc(32 / 1563 * 100vw);
        margin-bottom: calc(120 / 1563 * 100vw);
        &:nth-child(odd) {
          margin-right: calc(160 / 4000 * 100vw);
        }
      }
      &-link {
        display: inline-block;
        text-decoration: none;
        &-photo {
          width: calc(850 / 4000 * 100vw);
          @include iPad() {
            width: 100%;
          }
        }
        &-type {
          font-size: calc(54 / 4000 * 100vw);
          color: #3d3936;
          text-align: center;
          padding-top: calc(40 / 2250 * 100vh);
          // padding-bottom: calc(40 / 2250 * 100vh);
        }
      }
    }
  }
}

</style>
