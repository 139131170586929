<template>
  <div class="pagination">
    <aside class="sidebar">
      <nav>
        <ul class="sidebar-menu">
          <li class="sidebar-menu-item">
            <router-link :to="{ name: 'PriceList'}">價目表</router-link>
          </li>
          <li class="sidebar-menu-item">
            <router-link :to="{ name: 'AppraisalList' }">搬家估價單</router-link>
          </li>
          <li class="sidebar-menu-item">
            <router-link :to="{ name: 'CommonProblem' }">常見問題</router-link>
          </li>
          <li class="sidebar-menu-item">
            <router-link :class="{ active: currentRoute === 'MoveHouseTidbitsAlbum' }" :to="{ name: 'MoveHouseTidbits' }">搬家花絮</router-link>
          </li>
        </ul>
      </nav>
    </aside>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'Pagination',
  setup () {
    const currentRoute = computed(() => {
      return useRoute().name
    })

    onMounted(() => {
      // setTimeout(() => scrollFix(route.hash), 1)
    })

    return { currentRoute }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.pagination {
  display: flex;
  padding-top: calc(480 / 2250 * 100vh);
  @include iPad() {
    padding-top: calc(364 / 2779 * 100vh);
  }
}

.sidebar {
  // width: calc(750 / 4000 * 100vw);
  width: 20%;
  height: 100vh;
  @include iPad() {
    display: none;
  }
  &-menu {
    padding-top: calc(47 / 2250 * 100vh);
    padding-bottom: calc(137 / 2250 * 100vh);
    border-right: calc(20 / 4000 * 100vw) solid #d6d6d6;
    &-item {
      display: flex;
      justify-content: center;
      padding-left: calc(220 / 4000 * 100vw);
      padding-right: calc(105 / 4000 * 100vw);
      margin-bottom: calc(58 / 2250 * 100vh);
      a {
        display: inline-block;
        width: calc(410 / 4000 * 100vw);
        padding-top: calc(41.5 / 2250 * 100vh);
        // padding-right: calc(80 / 4000 * 100vw);
        padding-bottom: calc(41.5 / 2250 * 100vh);
        // padding-left: calc(80 / 4000 * 100vw);
        border-radius: 50rem;
        font-size: calc(58 / 4000 * 100vw);
        white-space: nowrap;
        text-decoration: none;
        text-align: center;
        // text-indent: -0.15rem;
        letter-spacing: 0.15rem;
        color: #ffffff;
        background-color: #2e3f8d;
      }
    }
  }
}

.router-link-active.router-link-exact-active {
  background-color: rgba(98, 119, 185, .9);
}

.active {
  background-color: rgba(98, 119, 185, .9) !important;
}

.content {
  width: 100%;
  height: 100%;
}
</style>
