<template>
  <div class="move-house-tidbits-album">
    <!-- <p>{{ $route.params.id }}</p> -->
    <ul class="move-house-tidbits-album-list" v-if="$route.params.id == 1">
      <li class="move-house-tidbits-album-list-item" v-for="(item, index) in album1" :key="index">
        <img :src="item.src" alt="">
      </li>
    </ul>
    <ul class="move-house-tidbits-album-list" v-else-if="$route.params.id == 2">
      <li class="move-house-tidbits-album-list-item" v-for="(item, index) in album2" :key="index">
        <img :src="item.src" alt="">
      </li>
    </ul>
    <ul class="move-house-tidbits-album-list" v-else-if="$route.params.id == 3">
      <li class="move-house-tidbits-album-list-item" v-for="(item, index) in album3" :key="index">
        <img :src="item.src" alt="">
      </li>
    </ul>
    <ul class="move-house-tidbits-album-list" v-else-if="$route.params.id == 4">
      <li class="move-house-tidbits-album-list-item" v-for="(item, index) in album4" :key="index">
        <img :src="item.src" alt="">
      </li>
    </ul>
    <ul class="move-house-tidbits-album-list" v-else-if="$route.params.id == 5">
      <li class="move-house-tidbits-album-list-item" v-for="(item, index) in album5" :key="index">
        <img :src="item.src" alt="">
      </li>
    </ul>
    <ul class="move-house-tidbits-album-list" v-else-if="$route.params.id == 6">
      <li class="move-house-tidbits-album-list-item" v-for="(item, index) in album6" :key="index">
        <img :src="item.src" alt="">
      </li>
    </ul>
    <ul class="move-house-tidbits-album-list" v-else-if="$route.params.id == 7">
      <li class="move-house-tidbits-album-list-item" v-for="(item, index) in album7" :key="index">
        <img :src="item.src" alt="">
      </li>
    </ul>
  </div>
</template>

<script>
// import { ref, reactive } from 'vue'
import { reactive } from 'vue'
// import { useRoute } from 'vue-router'

export default {
  name: 'MoveHouseTidbitsAlbum',
  setup () {
    const album1 = reactive([
      { src: require('@/assets/搬家花絮相簿/搬家過程/1.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/2.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/3.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/4.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/5.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/6.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/7.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/8.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/9.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/10.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/11.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/12.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/13.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/14.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/15.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/16.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/17.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/18.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/19.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/20.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/21.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/22.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/23.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/24.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/25.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/26.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/27.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/28.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/29.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/30.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/31.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/32.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/33.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/34.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/35.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/36.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/37.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/38.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/39.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/40.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/41.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/42.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/43.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/44.jpg') },
      { src: require('@/assets/搬家花絮相簿/搬家過程/45.jpg') }
    ])
    const album2 = reactive([
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/1.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/2.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/3.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/4.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/5.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/6.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/7.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/8.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/9.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/10.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/11.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/12.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/13.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/14.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/15.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/16.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/17.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/18.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/19.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/20.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/21.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/22.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/23.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/24.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/25.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/26.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/27.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/28.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/29.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/30.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/31.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/32.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/33.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/34.jpg') },
      { src: require('@/assets/搬家花絮相簿/檔案銷毀/35.jpg') }
    ])
    const album3 = reactive([
      { src: require('@/assets/搬家花絮相簿/莊園整理/1.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/2.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/3.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/4.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/5.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/6.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/7.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/8.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/9.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/9.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/10.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/11.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/12.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/13.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/14.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/15.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/16.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/17.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/18.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/19.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/20.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/21.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/22.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/23.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/24.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/25.jpg') },
      { src: require('@/assets/搬家花絮相簿/莊園整理/26.jpg') }
    ])
    const album4 = reactive([
      { src: require('@/assets/搬家花絮相簿/割草/1.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/2.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/3.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/4.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/5.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/6.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/7.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/8.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/9.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/10.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/11.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/12.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/13.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/14.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/15.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/16.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/17.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/18.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/19.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/20.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/21.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/22.jpg') },
      { src: require('@/assets/搬家花絮相簿/割草/23.jpg') }
    ])
    const album5 = reactive([
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/1.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/2.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/3.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/4.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/5.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/6.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/7.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/8.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/9.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/10.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/11.jpg') },
      { src: require('@/assets/搬家花絮相簿/裝潢拆除/12.jpg') }
    ])
    const album6 = reactive([
      { src: require('@/assets/搬家花絮相簿/傢俱回收/1.jpg') },
      { src: require('@/assets/搬家花絮相簿/傢俱回收/2.jpg') },
      { src: require('@/assets/搬家花絮相簿/傢俱回收/3.jpg') },
      { src: require('@/assets/搬家花絮相簿/傢俱回收/4.jpg') },
      { src: require('@/assets/搬家花絮相簿/傢俱回收/5.jpg') },
      { src: require('@/assets/搬家花絮相簿/傢俱回收/6.jpg') }
    ])
    const album7 = reactive([
      { src: require('@/assets/搬家花絮相簿/回頭車/1.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/2.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/3.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/4.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/5.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/6.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/7.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/8.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/9.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/10.jpg') },
      { src: require('@/assets/搬家花絮相簿/回頭車/11.jpg') }
    ])
    // const route = useRoute()
    // const test = ref('album' + route.params.id)

    return { album1, album2, album3, album4, album5, album6, album7 }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.move-house-tidbits-album {
  padding-left: calc(95 / 4000 * 100vw);
  @include iPad() {
    padding-left: calc(70 / 1563 * 100vw);
    padding-right: calc(70 / 1563 * 100vw);
  }
  &-list {
    display: flex;
    // justify-content: space-around;
    flex-wrap: wrap;
    @include iPad() {
      margin-top: calc(140 / 1563 * 100vw);
    }
    &-item {
      width: calc(800 / 4000 * 100vw);
      // height: calc(600 / 4000 * 100vw);
      margin-top: calc(40 / 4000 * 100vw);
      margin-right: calc(80 / 4000 * 100vw);
      margin-bottom: calc(80 / 4000 * 100vw);
      // margin-left: calc(40 / 4000 * 100vw);
      @include iPad() {
        width: calc(680 / 1563 * 100vw);
        // height: calc(680 / 1563 * 100vw);
        margin-top: calc(40 / 1562 * 100vw);
        // margin-right: auto;
        margin-bottom: calc(80 / 1562 * 100vw);
        // margin-left: auto;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 0;
        &:nth-child(odd) {
          margin-right: calc(63 / 1562 * 100vw);
        }
        &:nth-child(even) {
          // margin-right: 0;
        }
      }
      img {
        width: calc(800 / 4000 * 100vw);
        min-height: calc(600 / 4000 * 100vw);
        max-height: calc(1000 / 4000 * 100vw);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        @include iPad() {
          width: calc(800 / 1562 * 100vw);
          min-height: calc(600 / 1562 * 100vw);
          max-height: calc(900 / 1562 * 100vw);
        }
      }
    }
  }
}

.router-link-active.router-link-exact-active {
  background-color: rgba(98, 119, 185, .9);
}

</style>
