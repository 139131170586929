<template>
  <div class="common-problem">
    <h2 class="common-problem-title"><span>常見問題</span></h2>
    <ol class="common-problem-list">
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">1. 學生優惠搬家?</h3>
        <p class="common-problem-list-item-answer">五路可提供自助搬家服務，以10公里內之距離搬家更省錢，詳情請來電洽詢。</p>
      </li>
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">2. 易碎物品如何包裝及在箱外有甚麼樣的提示貼紙可以用呢？</h3>
        <p class="common-problem-list-item-answer">建議客戶在包裝易碎品時可以使用 報紙或廣告廢紙、氣泡紙、鋒巢紙、衣服等方式包覆。本公司提供貼紙給予客戶黏貼於外箱明顯處，也利於搬家時辨識。</p>
      </li>
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">3. 搬家後廢棄的大型家具該如何處理?</h3>
        <p class="common-problem-list-item-answer">不需要的家具在估價時可先告知，並且於當天搬家完畢後，我們會回程就當天搬走，費用一台3.49頓為3500(不含樓層，需另外計費)裝滿為止，數量多寡依車輛滿載狀況計算。</p>
      </li>
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">4. 搬家前有哪些注意事項?</h3>
        <p class="common-problem-list-item-answer">壹、 有抽屜的傢俱，內容物須全數清空，鎖上或是使用布紋膠帶黏貼，或者由本公司處理包覆即可。切記請勿使用透明膠帶會使家聚落漆及脫膠等情況</p>
        <p class="common-problem-list-item-answer">貳、 冰箱請於搬家前一天將內容物取出，拔下插頭即可，交由本公司專業團隊進行防撞保護措施。搬遷出定位後請靜置4-5小時以上，若在夏天或周圍溫度交高之場所時，需要較久的時間約半天~一天，才能讓庫內充分冷卻。本公司到府服務業者時會先將冰箱進行排水。</p>
        <p class="common-problem-list-item-answer">參、 若有電腦需要搬運，應先將電腦備份，以放狀搬運中因搖晃而導致資料遺失。</p>
        <p class="common-problem-list-item-answer">肆、 有無特殊或貴重物品如古董、珠寶、字畫、精密儀器、陶器、玻璃、藝術品、大型魚缸、營業用雙門對開冰箱、滾筒洗衣機、神桌、佛像、進口高級家具及其等物件，應於搬運前事先向本公司言明，以防運送途中損壞或是被當成一般傢俱搬運</p>
        <p class="common-problem-list-item-answer">伍、 若有佛教或道教信仰者或是長背觀念較傳統者，欲搬家前可先參閱台灣傳統農民曆，並挑選良成即日進行預約搬家。</p>
      </li>
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">5. 有提供紙箱方便包裝嗎？</h3>
        <p class="common-problem-list-item-answer">本公司提供50cm*35cm*35cm規格之紙箱，一個紙箱價格為10元，保鮮膜等耗材均免費由五路為您現場包裝。</p>
      </li>
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">6. 簽約理賠</h3>
        <p class="common-problem-list-item-answer">五路在每一次搬家，對每一位客戶都是非常重要的互動關係，我們亦會以認真負責的態度理賠客戶的損失。
簽約保證賠償制度：國民實施簽約制度，免費到府估價，開立估價契約書，並詳細載明服務項目。"建立賠償制度，保證不亂加價"！</p>
        <p class="common-problem-list-item-answer">◎ 以下為本公司的理賠條款：</p>
        <p class="common-problem-list-item-answer">五路提供"搬遷後保固"，若搬運物品有毀損滅失時，消費者需在搬運完成後，公司搬家七日內向業者反應，逾期未告知業者，將不受理投訴。</p>
        <p class="common-problem-list-item-answer">
          <ul class="common-problem-list-item-answer-list">
            <li>A： 搬運賠償限於工作人員作業所致，若搬家搬運人員已於搬動前告知，有可能損害物品之風險，但消費者仍執意要搬運，一切責任由消費者自負。</li>
            <li>B： 損壞賠償原則以物品外觀在，搬家搬運過程中之新傷痕為限，物品損壞以修繕為原則，但若修繕費用高於折舊後之現值，則以折舊後之現值為理賠標準。</li>
            <li>C： 若物品遺失，則需提供價值憑證(如：發票等)後，以使用折舊年限之價值，來做為理賠之標準；以搬遷契約內容為理賠之依據，單次搬遷案件理賠上限為，新台幣兩萬元。</li>
            <li>D： 打包裝箱之內容物，若由客戶自行打包，則代表客戶應自行，做好箱內保護的工作，若搬運途中無碰撞或外力重擊，國民搬家將不負擔內容物之安全性。</li>
          </ul>
        </p>
        <p class="common-problem-list-item-answer">高單價、體積小、私人紀念性之物品 ，請自行攜帶，如有損壞恕難負責。貴重物品如：現金、支票、珠寶、手錶、重要文件(證件、證書、印章、存摺、公文)、古董、字畫、藝術品或其它小型貴重物；高價電子產品如：手機、PDA、隨身碟、MP3等；易碎物如：玻璃、琉璃、瓷器等，請於搬運前告知，未告知者不予理賠。</p>
        <p class="common-problem-list-item-answer">高單價藝術品或家具務必使用訂製木箱或是專業包裝搬運，若消費者不聽專業建言執意搬運，一切責任由消費者負責。</p>
        <p class="common-problem-list-item-answer">搬家服務立基於雙方互信，所有物品皆依消費者指示，搬運與擺放，請於搬運中確認滿車，並於搬運完成後確認空車。若作業人員告知請其確認但未確認者，視同放棄其確認權利，後續若有任何爭議，以完工後之簽章為雙方合議之認定。</p>
        <p class="common-problem-list-item-answer">若選擇開放式廂型車(無頂)來做搬運，其裝載容積將以高度超出車頭50公分(法定載貨限高) ，或是與車身同寬為原則(如需進入地下室工作，其堆疊與車頭同高為原則)。</p>
        <p class="common-problem-list-item-answer"></p>
      </li>
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">7. 搬家公司會幫忙拆解組裝傢俱嗎？</h3>
        <p class="common-problem-list-item-answer">除了IKEA及系統家具以外無法處理，一般傢俱衣櫥，收納櫃，均可拆解組裝。</p>
      </li>
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">8. 搬屋前，該如何打包整理物品?</h3>
        <p class="common-problem-list-item-answer">需搬運的物件需搬家前完成打包，碗盤類需以棉紙或報紙單一個包裝好並立起來排放於紙箱內，四周圍可放衣物加以防撞；衣物、書籍及所有瑣碎物品都必須入箱。</p>
      </li>
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">9. 搬家後是否有清潔服務?</h3>
        <p class="common-problem-list-item-answer">這部分需要與業者洽談估價，額外付費。</p>
      </li>
      <li class="common-problem-list-item">
        <h3 class="common-problem-list-item-question">10. 長途及短途搬家費用如何計算?</h3>
        <p class="common-problem-list-item-answer">跨縣市基本上搬家的費用是由四大因素所組成的：</p>
        <p class="common-problem-list-item-answer">
          <ol class="common-problem-list-item-answer-list">
            <li class="common-problem-list-item-answer-list-item">1. 傢俱及物品的多寡</li>
            <li class="common-problem-list-item-answer-list-item">2. 傢俱搬運的難易度</li>
            <li class="common-problem-list-item-answer-list-item">3. 雙邊住家的樓層及中庭步行距離</li>
            <li class="common-problem-list-item-answer-list-item">4. 雙邊住家的搬遷距離</li>
            <li class="common-problem-list-item-answer-list-item">5. 雙邊有無電梯或地下室</li>
            <li class="common-problem-list-item-answer-list-item">6. 特殊地形例如:中庭步行距離，樓中樓，轉乘電梯，大門前階梯斜坡等。</li>
          </ol>
        </p>
      </li>
    </ol>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'CommonProblem',
  setup () {
    useMeta({
      title: '常見問題'
    })
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.common-problem {
  padding-left: calc(132 / 4000 * 100vw);
  padding-bottom: calc(128 / 2250 * 100vh);
  @include iPad() {
    padding-left: 0;
  }
  &-title {
    display: none;
    @include iPad() {
      display: flex;
      justify-content: center;
      margin-top: calc(40 / 1563 * 100vw);
      margin-bottom: calc(120 / 1563 * 100vw);
    }
    span {
      padding: calc(40 / 1563 * 100vw) calc(120 / 1563 * 100vw);
      color: #ffffff;
      background-color: #2e3f8d;
      border-radius: 50rem;
      font-size: calc(60 / 1563 * 100vw);
    }
  }
  &-list {
    &-item {
      width: 60vw;
      margin-right: calc(232 / 4000 * 100vw);
      @include iPad() {
        width: calc(1381 / 1563 * 100vw);
        margin-right: 0;
        margin: 0 auto;
      }
      &:not(:nth-child(1)) {
        margin-top: calc(106 / 2250 * 100vh);
      }
      &-question {
        font-size: calc(68 / 4000 * 100vw);
        letter-spacing: .2rem;
        border-radius: 50rem;
        padding: calc(87 / 2250 * 100vh) calc(124 / 4000 * 100vw);
        margin-bottom: calc(106 / 2250 * 100vh);
        background-color: #ec9100;
        @include iPad() {
          font-size: .875rem;
          line-height: 1.5;
          padding: calc(40 / 1563 * 100vw) calc(54 / 1563 * 100vw);
        }
      }
      &-answer {
        font-size: calc(48 / 4000 * 100vw);
        letter-spacing: .2rem;
        line-height: 1.5;
        margin-left: calc(125 / 4000 * 100vw);
        margin-right: calc(125 / 4000 * 100vw);
        margin-bottom: calc(72 / 2250 * 100vh);
        @include iPad() {
          font-size: .875rem;
        }
        &-list {
          margin-left: calc(125 / 4000 * 100vw);
          margin-right: calc(125 / 4000 * 100vw);
          li {
            font-size: calc(48 / 4000 * 100vw);
            letter-spacing: .2rem;
            padding-left: 2rem;
            line-height: 1.5;
            margin-bottom: calc(36 / 2250 * 100vh);
            @include iPad() {
              font-size: .875rem;
            }
          }
        }
      }
    }
  }
}
</style>
