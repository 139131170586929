<template>
  <div class="price-list">
    <a ref="downloadBtn" href="#" class="price-list-download-btn" @click.prevent="downloadAppraisalList()">下載估價表單</a>
    <img src="../assets/價目表一.png" alt="" class="price-list-table1">
    <img src="../assets/價目表二.png" alt="" class="price-list-table2">
  </div>
</template>

<script>
// import { ref } from 'vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'PriceList',
  setup () {
    const downloadAppraisalList = () => {
      // reference source: https://stackoverflow.com/questions/52817280/problem-downloading-a-pdf-blob-in-javascript
      fetch('/五路搬家估價單.pdf').then(res => res.arrayBuffer()).then(res => {
        // set the blog type to final pdf
        const file = new Blob([res], { type: 'application/pdf' })
        // process to auto download it
        const fileURL = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = '五路搬家估價單.pdf'
        link.click()
      })
    }

    useMeta({
      title: '價目表'
    })

    return { downloadAppraisalList }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.price-list {
  padding-top: calc(21 / 2250 * 100vh);
  padding-left: calc(120 / 4000 * 100vw);
  @include iPad() {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &-download-btn {
    display: inline-block;
    width: calc(600 / 4000  * 100vw);
    height: calc(200 / 4000 * 100vw);
    border-radius: 50rem;
    // padding-top: calc(46 / 2250 * 100vh);
    // padding-right: calc(80 / 4000 * 100vw);
    // padding-bottom: calc(46 / 2250 * 100vh);
    // padding-left: calc(80 / 4000 * 100vw);
    margin-bottom: calc(62 / 2250 * 100vh);
    font-size: calc(62 / 4000 * 100vw);
    text-decoration: none;
    text-indent: 130%;
    overflow: hidden;
    white-space:nowrap;
    text-align: center;
    color: #ffffff;
    background-image: url(../assets/下載估價單.png);
    background-repeat: no-repeat;
    background-size: cover;
    @include iPad() {
      margin-bottom: calc(75 / 2779 * 100vh);
      align-self: center;
      font-size: calc(60 / 1563 * 100vw);
      padding: calc(40 / 1563 * 100vw) calc(120 / 1563 * 100vw);
    }
  }
  &-table1 {
    display: block;
    width: calc(2440 / 4000 * 100vw);
    // height: calc(1078 / 2250 * 100vh);
    margin-bottom: calc(117 / 2250 * 100vh);
    @include iPad() {
      width: calc(1377 / 1562  * 100vw);
      margin: 0 auto;
      margin-bottom: calc(81 / 2779 * 100vh);
    }
  }
  &-table2 {
    display: block;
    width: calc(2440 / 4000 * 100vw);
    margin-bottom: calc(212 / 2250 * 100vh);
    // height: calc(2376 / 3188 * 100vh);
    @include iPad() {
      width: calc(1377 / 1562  * 100vw);
      margin: 0 auto;
      margin-bottom: calc(172 / 2779 * 100vh);
    }
  }
}
</style>
